<template>
    <v-card height="100%">
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>{{name}}</v-toolbar-title>

            <v-spacer />

            <v-btn small text @click="confirm">Confirm</v-btn
            >
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form class="mt-3">

                    <div class="d-flex ml-n3 mr-n3">
                        <div class="flex-grow-0 align-self-center">
                            <v-icon size="40" color="warning">mdi-alert</v-icon>
                        </div>
                        <div class="ml-3 flex-grow-1 align-self-center">
                            Please confirm facts entered as correct.
                        </div>
                    </div>
                    
                    <v-row class="mt-3">
                        <v-textarea
                            label="Comment"
                            v-model="comment"
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import sharedoActionService from "../../services/sharedo/sharedoActionService.js";

export default {
    props: {
        sharedoId: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        toPhaseSystemName: {
            type: String,
            required: true,
        },
        shouldCloseUi: {
            type: Boolean
        }
    },
    data: function () {
        return {
            comment: null,
        };
    },
    methods: {
        confirm: function () {

            var opts = {
                sharedoId: this.sharedoId,
                toPhaseSystemName: this.toPhaseSystemName,
                name: this.name,
                callback: this.afterTransitionTo.bind(this),
                shouldCloseUi: this.shouldCloseUi,
                reasonText: this.comment,
            };
            
            sharedoActionService.transitionTo(opts);
        },

        afterTransitionTo: function () {
            this.close(true);
        },

        close: function (result) {
            this.$emit("close", result);
        },
    },
};
</script>